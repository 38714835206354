// src/ProtectedRoute.js
import React, { useContext } from 'react';
import { UserContext } from './UserContext';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const currentUser = useContext(UserContext);

  if (currentUser === undefined) {
    // While checking auth state, you can return a loading indicator or null
    return null;
  }

  return currentUser ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;

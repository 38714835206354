// src/pages/SharedPrayerCardPage.js
import React, { useState, useEffect } from "react";
import VirtualPrayerCard from "../components/PrayerCard/VirtualPrayerCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { CircularProgress, Typography } from "@mui/material";

const GradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #e0eafc, #cfdef3, #a6c0fe, #f68084);
  background-size: 400% 400%;
  animation: ${GradientAnimation} 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function SharedPrayerCardPage() {
  const { shareId } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch prayer card data using shareId
    axios
      .get(`https://backend.remembrancetechnology.com/users/share/${shareId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching shared prayer card:", err);
        setError("Prayer card not found.");
      });
  }, [shareId]);

  if (error) {
    return (
      <Background>
        <Typography variant="h6" color="#fff">
          {error}
        </Typography>
      </Background>
    );
  }

  if (!data) {
    return (
      <Background>
        <CircularProgress color="inherit" />
      </Background>
    );
  }

  return (
    <Background>
      <ContainerWrapper>
        <VirtualPrayerCard data={data} />
      </ContainerWrapper>
    </Background>
  );
}

export default SharedPrayerCardPage;

// Pricing.js
import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Grid,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';

const plansData = [
  {
    id: 'basic',
    name: 'Basic',
    monthlyPrice: 'Free',
    features: [
      'Create one memorial card',
      'Basic templates',
      'Limited customization options',
      'Up to 3 photos per card',
      'Basic customer support',
      'Hosted for 30 days',
      'Standard sharing options',
    ],
    isPopular: false,
  },
  {
    id: 'standard',
    name: 'Standard',
    monthlyPrice: '$5',
    features: [
      'Create up to 5 memorial cards',
      'Premium templates',
      'Advanced customization options',
      'Up to 10 photos per card',
      'Priority customer support',
      'Hosted for 1 year',
      'Social media sharing capabilities',
      'Real-time previews',
    ],
    isPopular: true,
  },
  {
    id: 'premium',
    name: 'Premium',
    monthlyPrice: '$8',
    features: [
      'Create unlimited memorial cards',
      'All templates and themes',
      'Full customization options',
      'Unlimited photos per card',
      'Premium customer support',
      'Hosted indefinitely',
      'Advanced sharing options',
      'Animations and music features',
      'Interactive cards',
      'Smooth, modern UI elements',
    ],
    isPopular: false,
  },
];

const Pricing = () => {
  const theme = useTheme();

  // Debug: Log theme to verify it's updated
  console.log('Current Theme in Pricing:', theme);

  return (
    <Box
      component="section"
      sx={{
        py: 8,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 4, color: 'primary.main' }} // Ensure color uses theme
        >
          Choose Your Plan
        </Typography>
        <Grid container spacing={4}>
          {plansData.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              <Card
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  border:
                    plan.isPopular
                      ? `2px solid ${theme.palette.primary.main}`
                      : `1px solid ${theme.palette.divider}`,
                  boxShadow: plan.isPopular
                    ? `0 4px 20px rgba(0, 44, 88, 0.2)`
                    : `0 2px 10px rgba(0, 0, 0, 0.1)`,
                }}
              >
                {plan.isPopular && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: theme.spacing(2),
                      right: theme.spacing(2),
                      zIndex: 1,
                    }}
                  >
                    <Chip
                      icon={<FontAwesomeIcon icon={faStar} />}
                      label="Most Popular"
                      color="primary"
                      variant="filled"
                    />
                  </Box>
                )}
                <CardHeader
                  title={
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: 'bold', color: 'primary.main' }}
                    >
                      {plan.name}
                    </Typography>
                  }
                  sx={{
                    textAlign: 'center',
                    pt: plan.isPopular ? 4 : 2,
                    pb: 0,
                  }}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={{ mb: 2 }}
                  >
                    {plan.monthlyPrice !== 'Free' ? `${plan.monthlyPrice}/mo` : plan.monthlyPrice}
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      listStyle: 'none',
                      padding: 0,
                      width: '100%',
                      mb: 4,
                    }}
                  >
                    {plan.features.map((feature, idx) => (
                      <Box
                        component="li"
                        key={`${plan.id}-feature-${idx}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ color: theme.palette.primary.main, marginRight: 8 }}
                        />
                        <Typography variant="body1">{feature}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Button
                    variant={plan.isPopular ? 'contained' : 'outlined'}
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{
                      mt: 'auto',
                      textTransform: 'none',
                    }}
                  >
                    Get Started
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing;

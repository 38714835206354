// src/components/CombinedSettings.js
import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
  Grid,
  Alert,
  Container,
  Paper,
  Divider,
} from "@mui/material";
import axios from "axios";
import { UserContext } from "../../UserContext";
import { auth } from "../../firebase";

function CombinedSettings() {
  const user = useContext(UserContext);

  // State for profile settings
  const [profile, setProfile] = useState({
    email: "",
    name: "",
    hometown: "",
    about: "",
    profileImage: "",
    phone: "",
    address: "",
    occupation: "",
  });

  const [profileError, setProfileError] = useState("");
  const [profileSuccess, setProfileSuccess] = useState("");

  // State for prayer card settings
  const [prayerCard, setPrayerCard] = useState({
    name: "",
    profileImage: "",
    frontImage: "",
    backImage: "",
    dates: "",
    prayer: "",
    occasion: "",
    location: "",
  });

  const [prayerCardError, setPrayerCardError] = useState("");
  const [prayerCardSuccess, setPrayerCardSuccess] = useState("");

  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isEditingPrayerCard, setIsEditingPrayerCard] = useState(false);

  useEffect(() => {
    if (user) {
      auth.currentUser.getIdToken().then((token) => {
        // Fetch profile settings
        axios
          .get(`https://backend.remembrancetechnology.com/users/${user.uid}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data) {
              setProfile(res.data);
            } else {
              setProfile({ ...profile, email: user.email });
            }
          })
          .catch((err) => {
            console.error("Error fetching profile:", err);
            setProfileError("Failed to fetch profile information.");
          });

        // Fetch prayer card settings
        axios
          .get(
            `https://backend.remembrancetechnology.com/${user.uid}/prayerCard`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.data) {
              setPrayerCard(res.data);
            }
          })
          .catch((err) => {
            console.error("Error fetching prayer card:", err);
            setPrayerCardError("Failed to fetch prayer card information.");
          });
      });
    }
  }, [user]);

  // Handle changes in profile settings
  const handleProfileChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  // Handle changes in prayer card settings
  const handlePrayerCardChange = (e) => {
    setPrayerCard({ ...prayerCard, [e.target.name]: e.target.value });
  };

  // Handle profile form submission
  const handleProfileSubmit = (e) => {
    e.preventDefault();
    setProfileError("");
    setProfileSuccess("");
    auth.currentUser.getIdToken().then((token) => {
      axios
        .post(
          "https://backend.remembrancetechnology.com/users",
          {
            uid: user.uid,
            ...profile,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(() => {
          setProfileSuccess("Profile updated successfully!");
          setIsEditingProfile(false);
        })
        .catch((err) => {
          console.error(
            "Error updating profile:",
            err.response?.data || err.message
          );
          setProfileError("Failed to update profile. Please try again.");
        });
    });
  };

  // Handle prayer card form submission
  const handlePrayerCardSubmit = (e) => {
    e.preventDefault();
    setPrayerCardError("");
    setPrayerCardSuccess("");
    auth.currentUser.getIdToken().then((token) => {
      axios
        .post(
          "https://backend.remembrancetechnology.com/prayerCard",
          {
            uid: user.uid,
            prayerCard,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(() => {
          setPrayerCardSuccess("Prayer card updated successfully!");
          setIsEditingPrayerCard(false);
        })
        .catch((err) => {
          console.error(
            "Error updating prayer card:",
            err.response?.data || err.message
          );
          setPrayerCardError("Failed to update prayer card. Please try again.");
        });
    });
  };

  // If user is not logged in, prompt them to log in
  if (!user) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">
          Please log in to view your settings.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Profile Settings */}
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Profile Settings
        </Typography>

        {profileError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {profileError}
          </Alert>
        )}

        {profileSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {profileSuccess}
          </Alert>
        )}

        <Box display="flex" justifyContent="center" mb={2}>
          <Avatar
            src={profile.profileImage}
            alt={profile.name}
            sx={{ width: 120, height: 120 }}
          />
        </Box>

        {!isEditingProfile && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditingProfile(true)}
            sx={{ mb: 2 }}
          >
            Edit Profile
          </Button>
        )}

        <form onSubmit={handleProfileSubmit}>
          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={profile.name}
                onChange={handleProfileChange}
                required
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                value={profile.email}
                disabled
              />
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone"
                name="phone"
                fullWidth
                value={profile.phone}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                value={profile.address}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Hometown */}
            <Grid item xs={12}>
              <TextField
                label="Hometown"
                name="hometown"
                fullWidth
                value={profile.hometown}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Occupation */}
            <Grid item xs={12}>
              <TextField
                label="Occupation"
                name="occupation"
                fullWidth
                value={profile.occupation}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* About */}
            <Grid item xs={12}>
              <TextField
                label="About"
                name="about"
                fullWidth
                multiline
                rows={4}
                value={profile.about}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Profile Image URL */}
            <Grid item xs={12}>
              <TextField
                label="Profile Image URL"
                name="profileImage"
                fullWidth
                value={profile.profileImage}
                onChange={handleProfileChange}
                disabled={!isEditingProfile}
              />
            </Grid>

            {/* Save and Cancel Buttons */}
            {isEditingProfile && (
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  Save Changes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setIsEditingProfile(false);
                    // Optionally reset the form to original values here
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>

      <Divider sx={{ mb: 4 }} />

      {/* Prayer Card Settings */}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Prayer Card Settings
        </Typography>

        {prayerCardError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {prayerCardError}
          </Alert>
        )}

        {prayerCardSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {prayerCardSuccess}
          </Alert>
        )}

        {!isEditingPrayerCard && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditingPrayerCard(true)}
            sx={{ mb: 2 }}
          >
            Edit Prayer Card
          </Button>
        )}

        <form onSubmit={handlePrayerCardSubmit}>
          <Grid container spacing={2}>
            {/* Name on Prayer Card */}
            <Grid item xs={12}>
              <TextField
                label="Name on Prayer Card"
                name="name"
                fullWidth
                value={prayerCard.name}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
                required
              />
            </Grid>

            {/* Prayer Card Profile Image URL */}
            <Grid item xs={12}>
              <TextField
                label="Prayer Card Profile Image URL"
                name="profileImage"
                fullWidth
                value={prayerCard.profileImage}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Front Image URL */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Front Image URL"
                name="frontImage"
                fullWidth
                value={prayerCard.frontImage}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Back Image URL */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Back Image URL"
                name="backImage"
                fullWidth
                value={prayerCard.backImage}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Dates */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Dates"
                name="dates"
                fullWidth
                value={prayerCard.dates}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Occasion */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Occasion"
                name="occasion"
                fullWidth
                value={prayerCard.occasion}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Location */}
            <Grid item xs={12}>
              <TextField
                label="Location"
                name="location"
                fullWidth
                value={prayerCard.location}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
              />
            </Grid>

            {/* Prayer Text */}
            <Grid item xs={12}>
              <TextField
                label="Prayer Text"
                name="prayer"
                fullWidth
                multiline
                rows={6}
                value={prayerCard.prayer}
                onChange={handlePrayerCardChange}
                disabled={!isEditingPrayerCard}
                required
              />
            </Grid>

            {/* Save and Cancel Buttons */}
            {isEditingPrayerCard && (
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  Save Prayer Card
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setIsEditingPrayerCard(false);
                    // Optionally reset the form to original values here
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default CombinedSettings;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtkX43x6lAiwiKRlBveGNAsH_GJ8FWf-s",
  authDomain: "reminisceme-1cf59.firebaseapp.com",
  projectId: "reminisceme-1cf59",
  storageBucket: "reminisceme-1cf59.appspot.com",
  messagingSenderId: "159854333134",
  appId: "1:159854333134:web:2dcc28119987315329949f",
  measurementId: "G-3BJ533H5RX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted.
    // You can now sign in the user or do other auth operations
  })
  .catch((error) => {
    // Handle errors here
    console.error('Firebase persistence error:', error);
  });

export { auth };
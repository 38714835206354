// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define your custom font family
const fontFamily = [
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

// Create the master theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#033f63", // Your desired primary color
    },
    secondary: {
      main: "#fedc97", // Your desired secondary color
    },
    error: {
      main: "#f44336", // Error color
    },
    info: {
      main: "#fedc97", // Info color
    },
    background: {
      default: "#f5f5f5", // Background color
      paper: "#ffffff", // Paper (card) background
    },
  },
  typography: {
    fontFamily: fontFamily,
    h2: {
      fontWeight: 700,
      fontSize: "2.5rem",
      color: "#2c3e50",
      textAlign: "center",
      marginBottom: "3rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.6rem",
      color: "#2c3e50",
      marginBottom: "1.5rem",
    },
    body1: {
      fontSize: "1rem",
      color: "#7f8c8d",
    },
    button: {
      fontWeight: "bold",
      textTransform: "none",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.05)",
          transition: "transform 0.3s",
          "&:hover": {
            transform: "translateY(-10px)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          padding: "14px 40px",
          fontSize: "1.1rem",
          backgroundColor: "#033f63",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#13856a",
          },
        },
      },
    },
  },
});

export default theme;

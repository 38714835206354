// src/components/NavigationBar.js
import React, { useContext, useState, memo } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Box,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { UserContext } from "../../UserContext";
import { auth } from "../../firebase";
import { Link as RouterLink } from "react-router-dom";

const NavigationBar = () => {
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      handleMenuClose();
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  if (user === undefined) {
    return null;
  }

  const menuItems = [
    { id: "features", text: "Features", link: "/#features" },
    { id: "testimonials", text: "Testimonials", link: "/#testimonials" },
    { id: "get-started", text: "Get Started", link: "/#cta" },
    { id: "prayer-card", text: "Prayer Card", link: "/prayer-card" }, // New path
    user
      ? { id: "profile", text: "Profile", link: "/profile" }
      : { id: "sign-up", text: "Sign Up", link: "/signup" },
    user
      ? { id: "logout", text: "Logout", action: handleLogout }
      : { id: "log-in", text: "Log In", link: "/login" },
  ];

  return (
    <AppBar
      position="fixed"
      color="primary"
      elevation={4}
      sx={{
        top: 0, // Ensure the AppBar is at the top
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Toolbar
        sx={{
          paddingX: { xs: 1, sm: 2 },
          minHeight: "unset",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Brand Logo or Name */}
        <Typography
          variant={isMobile ? "h6" : "h5"}
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: "none",
            color: "inherit",
            fontWeight: "bold",
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          Memoria
        </Typography>

        {/* Mobile View: Hamburger Menu */}
        {isMobile ? (
          <>
            <Tooltip title="Menu">
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                aria-controls={anchorEl ? "navigation-menu" : undefined}
                aria-haspopup="true"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="navigation-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  width: "200px",
                  mt: 1.5,
                },
              }}
            >
              {menuItems.map((item) =>
                item.link ? (
                  <MenuItem
                    key={item.id}
                    component={RouterLink}
                    to={item.link}
                    onClick={handleMenuClose}
                    sx={{
                      fontSize: "0.9rem",
                      paddingY: 1,
                      paddingX: 2,
                    }}
                  >
                    {item.text}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={item.id}
                    onClick={item.action}
                    sx={{
                      fontSize: "0.9rem",
                      paddingY: 1,
                      paddingX: 2,
                    }}
                  >
                    {item.text}
                  </MenuItem>
                )
              )}
            </Menu>
          </>
        ) : (
          // Desktop View: Inline Menu Items
          <Box>
            {menuItems.map((item) =>
              item.link ? (
                <Button
                  key={item.id}
                  color="inherit"
                  component={RouterLink}
                  to={item.link}
                  sx={{
                    fontSize: "0.9rem",
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    paddingX: 2,
                    paddingY: 1,
                    textTransform: "none",
                  }}
                >
                  {item.text}
                </Button>
              ) : (
                <Button
                  key={item.id}
                  color="inherit"
                  onClick={item.action}
                  sx={{
                    fontSize: "0.9rem",
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    paddingX: 2,
                    paddingY: 1,
                    textTransform: "none",
                  }}
                >
                  {item.text}
                </Button>
              )
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default memo(NavigationBar);

// src/components/Footer.js
import React from 'react';
import { Box, Container, Typography, IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const socialIcons = [
  { icon: <FaFacebookF />, link: 'https://facebook.com' },
  { icon: <FaTwitter />, link: 'https://twitter.com' },
  { icon: <FaInstagram />, link: 'https://instagram.com' },
  { icon: <FaLinkedinIn />, link: 'https://linkedin.com' },
];

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define mobile as small screens and below

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        py: isMobile ? .25 : 2, // Adjust vertical padding based on screen size
        px: isMobile ? 1 : 0, // Optional: Add horizontal padding on mobile
        position: 'fixed', // Keep fixed if necessary; otherwise, consider 'static'
        bottom: 0,
        zindex: 1000,
        width: '100%',
        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)', // Optional: Add subtle shadow for separation
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'center' : 'space-between'}
          alignItems="center"
          textAlign={isMobile ? 'center' : 'left'}
        >
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            sx={{
              mb: isMobile ? 1 : 0, // Add margin bottom on mobile for spacing
              fontSize: isMobile ? '0.875rem' : '1rem', // Adjust font size
            }}
          >
            &copy; {new Date().getFullYear()} Memoria. All rights reserved.
          </Typography>
          <Box>
            {socialIcons.map((social, index) => (
              <IconButton
                key={index}
                component={Link}
                href={social.link}
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  // Adjust icon size based on screen size
                  fontSize: isMobile ? '1rem' : '1.25rem',
                  mx: isMobile ? 0.5 : 1, // Adjust horizontal margin
                }}
              >
                {social.icon}
              </IconButton>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;

// src/utils/copyToClipboard.js
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Text copied to clipboard");
    },
    (err) => {
      console.error("Could not copy text: ", err);
    }
  );
};

// src/components/CTA.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import styled from 'styled-components';

const CTASection = styled.section`
  background: #343a40;
  color: #fff;
  padding: 80px 0;
  text-align: center;
`;

function CTA() {
  return (
    <CTASection id="cta">
      <Container>
        <h2 className="mb-4">Start Creating Memories Today</h2>
        <Button variant="light" size="lg" href="/signup">
          Sign Up Now
        </Button>
      </Container>
    </CTASection>
  );
}

export default CTA;

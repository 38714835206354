// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Archive/Layout";
import PrayerCardPage from "./components/PrayerCardPage";
import MinimalLayout from "./components/MinimalLayout";
import ProductHighlight from "./components/HomePage/ProductHighlight";
import Features from "./components/HomePage/Features";
import Testimonials from "./components/HomePage/Testimonials";
import CTA from "./components/HomePage/CTA";
import Pricing from "./components/HomePage/Pricing";
import Login from "./components/Login&Out/Login";
import SignUp from "./components/Login&Out/SignUp";
import Profile from "./components/Profile/Profile";
import ProtectedRoute from "./ProtectedRoute";
import { UserProvider } from "./UserContext";
import { ToastContainer } from "react-toastify";
import SharedPrayerCardPage from "./pages/SharedPrayerCardPage";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Public Routes Without Layout (No Navbar and Footer) */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />

          {/* Public Route with Minimal Layout */}
          <Route
            path="/shared-prayer-card/:shareId"
            element={
              <MinimalLayout>
                <SharedPrayerCardPage />
              </MinimalLayout>
            }
          />

          {/* Protected Routes With Standard Layout (Navbar and Footer) */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Layout>
                  <Profile />
                </Layout>
              </ProtectedRoute>
            }
          />

          {/* Standard Public Routes With Standard Layout */}
          <Route
            path="/"
            element={
              <Layout>
                <ProductHighlight />
                <Features />
                <Testimonials />
                <CTA />
                <Pricing />
              </Layout>
            }
          />

          {/* Prayer Card Page With Standard Layout */}
          <Route
            path="/prayer-card"
            element={
              <Layout>
                <PrayerCardPage />
              </Layout>
            }
          />

          {/* Fallback Route (Optional) */}
          <Route
            path="*"
            element={
              <Layout>
                <h2>404 - Page Not Found</h2>
              </Layout>
            }
          />
        </Routes>
        <ToastContainer position="top-right" autoClose={5000} />
      </Router>
    </UserProvider>
  );
}

export default App;

// src/components/ProfilePage.js
import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import AccountSettings from "./CombinedSettings";
import PrayerCardSettings from "./PrayerCardSettings";

const drawerWidth = 240;

function ProfilePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Define header and footer heights
  const headerHeight = isMobile ? 56 : 64; // Adjust based on your actual header height
  const footerHeight = 56; // Adjust based on your actual footer height

  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("account");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (section) => {
    setSelectedSection(section);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* AppBar for mobile menu button */}
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            top: 0, // Align with the top of the screen
            zIndex: theme.zIndex.appBar,
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {selectedSection === "account"
                ? "Account Settings"
                : "Prayer Card Settings"}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: `${headerHeight}px`,
          marginBottom: `${footerHeight}px`,
          overflowY: "auto",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
          display: "flex",
          flexDirection: "column", // Ensure vertical stacking
          alignItems: "center", // Center horizontally
          justifyContent: "flex-start", // Align to top vertically
        }}
      >
        {selectedSection === "account" && <AccountSettings />}
      </Box>
    </Box>
  );
}

export default ProfilePage;

// src/pages/PrayerCardPage.js
import React, { useState, useEffect, useContext } from "react";
import VirtualPrayerCard from "./PrayerCard/VirtualPrayerCard";
import axios from "axios";
import { UserContext } from "../UserContext";
import { auth } from "../firebase";
import styled, { keyframes } from "styled-components";
import { CircularProgress, Typography } from "@mui/material";

const GradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #e0eafc, #cfdef3, #a6c0fe, #f68084);
  background-size: 400% 400%;
  animation: ${GradientAnimation} 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function PrayerCardPage() {
  const user = useContext(UserContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (user) {
      auth.currentUser.getIdToken().then((token) => {
        // Fetch prayer card data
        axios
          .get(
            `https://backend.remembrancetechnology.com/users/${user.uid}/prayerCard`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            const prayerCardData = res.data;
            setData(prayerCardData);
          })
          .catch((err) => {
            console.error("Error fetching prayer card data:", err);
            setError("Failed to fetch prayer card data.");
          });
      });
    }
  }, [user]);

  if (!user) {
    return (
      <Background>
        <Typography variant="h6" color="#fff">
          Please log in to view your prayer card.
        </Typography>
      </Background>
    );
  }

  if (error) {
    return (
      <Background>
        <Typography variant="h6" color="#fff">
          {error}
        </Typography>
      </Background>
    );
  }

  if (!data) {
    return (
      <Background>
        <CircularProgress color="inherit" />
      </Background>
    );
  }

  return (
    <Background>
      <ContainerWrapper>
        <VirtualPrayerCard data={data} />
      </ContainerWrapper>
    </Background>
  );
}

export default PrayerCardPage;

// src/components/VirtualPrayerCard.js
import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { FaFacebook, FaTwitter, FaInstagram, FaShareAlt } from "react-icons/fa";
import { copyToClipboard } from "../../utils/copyToClipboard";

const Card = styled(animated.div)`
  width: 300px;
  height: 500px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 90%;
    height: auto;
  }
`;

const CardSide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; /* Added */
  left: 0; /* Added */
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

// Front side with dynamic background
const Front = styled(CardSide)`
  background: ${(props) =>
    props.frontImage
      ? `url(${props.frontImage}) center/cover no-repeat`
      : `linear-gradient(
          0deg,
          rgba(2, 0, 36, 1) 0%,
          rgba(0, 0, 255, 1) 0%,
          rgba(235, 243, 255, 1) 0%,
          rgba(210, 247, 255, 1) 100%
        )`};
  color: #000;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); // Semi-transparent white overlay
  border-radius: 10px;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`;

const ShareIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  color: #555;
  z-index: 2;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;

  &:hover {
    transform: translateY(-3px);
    color: #4a90e2;
  }
`;

// Back side
const Back = styled(CardSide)`
  background: url(${(props) =>
      props.backImage ||
      "https://via.placeholder.com/300x500.png?text=Back+Image"})
    center/cover no-repeat;
  transform: rotateY(180deg);
  color: #fff;
  justify-content: center;
`;

const BackText = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
`;

const VirtualPrayerCard = ({ data }) => {
  const [flipped, setFlipped] = React.useState(false);

  const { transform } = useSpring({
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 75 },
  });

  // Function to generate shareable link
  const generateShareLink = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/shared-prayer-card/${data.shareId}`;
  };

  // Function to handle sharing
  const handleShare = (platform) => {
    const shareUrl = generateShareLink();
    const shareText = `Check out this prayer card: ${shareUrl}`;

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareUrl
          )}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent("Check out this prayer card!")}`,
          "_blank"
        );
        break;
      case "instagram":
        // Instagram doesn't support direct sharing via URL
        alert(
          "Instagram sharing is not supported directly. Please copy the link and share manually."
        );
        break;
      case "share":
        // Use the Web Share API if available
        if (navigator.share) {
          navigator
            .share({
              title: "Prayer Card",
              text: "Check out this prayer card!",
              url: shareUrl,
            })
            .catch((error) => console.log("Error sharing:", error));
        } else {
          // Fallback: copy to clipboard
          copyToClipboard(shareUrl);
          alert("Link copied to clipboard!");
        }
        break;
      default:
        break;
    }
  };

  return (
    <div onClick={() => setFlipped(!flipped)} style={{ cursor: "pointer" }}>
      <Card style={{ transform }}>
        {/* Front Side */}
        <Front frontImage={data.frontImage}>
          {/* Overlay */}
          <Overlay />

          {/* Share Icons */}
          <ShareIcons>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleShare("facebook");
              }}
            >
              <FaFacebook size={20} />
            </IconWrapper>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleShare("twitter");
              }}
            >
              <FaTwitter size={20} />
            </IconWrapper>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleShare("instagram");
              }}
            >
              <FaInstagram size={20} />
            </IconWrapper>
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleShare("share");
              }}
            >
              <FaShareAlt size={20} />
            </IconWrapper>
          </ShareIcons>

          {/* Content */}
          <Content>
            {/* Profile Image */}
            {data.profileImage ? (
              <ProfileImage src={data.profileImage} alt={data.name || "Name"} />
            ) : (
              <ProfileImage
                src="https://via.placeholder.com/120.png?text=Profile"
                alt="Default Profile"
              />
            )}

            {/* Prayer Card Details */}
            <h4>{data.name || "Name"}</h4>
            <p>{data.dates || "Dates"}</p>
            <p>{data.occasion || "Occasion"}</p>
            <p>{data.location || "Location"}</p>
          </Content>
        </Front>

        {/* Back Side */}
        <Back backImage={data.backImage}>
          <BackText>
            <p>{data.prayer || "Your prayer goes here..."}</p>
          </BackText>
        </Back>
      </Card>
    </div>
  );
};

VirtualPrayerCard.defaultProps = {
  data: {
    name: "Name",
    profileImage: "https://via.placeholder.com/120.png?text=Profile",
    frontImage: "https://via.placeholder.com/300x150.png?text=Front+Image",
    backImage: "https://via.placeholder.com/300x500.png?text=Back+Image",
    dates: "Dates",
    prayer: "Your prayer goes here...",
    occasion: "Occasion",
    location: "Location",
    shareId: "unique-share-id", // Default shareId
  },
};

export default VirtualPrayerCard;

// src/components/Features.js
import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import {
  Handshake as HandshakeIcon,
  Security as SecurityIcon,
  Image as ImageIcon,
  Public as PublicIcon,
  CloudUpload as CloudUploadIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
 
const Features = () => {
  const theme = useTheme();

  // Define motion variants for the cards
  const cardVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  // Features data with MUI icons and increased size
  const featuresData = [
    {
      icon: <HandshakeIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Bring Loved Ones Together',
      description:
        'Create a shared space where family and friends can contribute memories, stories, and tributes.',
    },
    {
      icon: <SecurityIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Privacy & Security',
      description:
        'Your memorials are protected with industry-leading security measures, ensuring peace of mind.',
    },
    {
      icon: <ImageIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Multimedia Galleries',
      description:
        'Upload photos and videos to celebrate the life and legacy of your loved one in vivid detail.',
    },
    {
      icon: <PublicIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Accessible Worldwide',
      description:
        'Friends and family can access the memorial from anywhere, fostering global connections.',
    },
    {
      icon: <CloudUploadIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Easy to Use',
      description:
        'Our intuitive platform makes it simple to create and manage memorials without any technical skills.',
    },
    {
      icon: <SmartphoneIcon sx={{ fontSize: '6rem', color: theme.palette.primary.main }} />,
      title: 'Mobile Friendly',
      description:
        'Enjoy a seamless experience on any device, so you can connect whenever and wherever you are.',
    },
  ];

  return (
    <Box
      component="section"
      id="features"
      sx={{
        paddingY: { xs: 8, md: 10 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h2" sx={{ mb: 6 }}>
          Celebrate Life's Precious Moments
        </Typography>
        <Grid container spacing={4}>
          {featuresData.map((feature) => (
            <Grid item xs={12} sm={6} md={4} key={feature.title}>
              <Card
                component={motion.div}
                variants={cardVariants}
                whileHover="hover"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 3,
                  boxShadow: 3,
                  textAlign: 'center',
                  minHeight: '360px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      marginBottom: 2,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography variant="h4" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1">{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', marginTop: 5 }}>
          <Button variant="contained" color="primary" size="large">
            Create a Memorial Today
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;

// src/components/ProductHighlight.js
import React, { useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import styled from "styled-components";
import InteractiveMemorialCard from "../PrayerCard/InteractiveMemorialCard";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";

const Section = styled.section`
  padding: 80px 0;
  background: linear-gradient(135deg, #fce8e6 0%, #d9e2ec 50%, #a3b9cc 100%);
`;

const Heading = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 50px;
  font-weight: 700;
  color: #2c3e50;
`;

const SubHeading = styled.h4`
  margin-top: 30px;
  font-weight: 600;
  color: #34495e;
`;

const Text = styled.p`
  font-size: 1.1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #7f8c8d;

  li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    svg {
      color: #27ae60;
      margin-right: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  background-color: #e74c3c;
  border: none;
  padding: 14px 30px;
  font-size: 1.2rem;
  font-weight: bold;

  &:hover {
    background-color: #c0392b;
  }
`;

const CardContainer = styled(motion.div)`
  cursor: pointer;
  display: inline-block;
`;

const ProductImage = styled(Image)`
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
`;

const ProductHighlight = () => {
  // State to control card flip
  const [flipped, setFlipped] = useState(false);

  // Toggle flip state
  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  // Sample card data
  const cardsData = [
    {
      id: 1,
      name: "Jane Doe",
      dates: "1950 - 2023",
      hometown: "Chicago, Illinois",
      about:
        "Jane was a loving mother, wife, and friend. She enjoyed gardening, painting, and spending time with her grandchildren.",
      profileImage:
        "https://www.wsb.com/wp-content/uploads/2021/06/Matalin_Mary_PROMOPIC-scaled.jpg",
      frontImage:
        "https://static.vecteezy.com/system/resources/previews/000/379/015/original/retro-decorative-hand-drawn-vintage-vector-frame-and-borders-design-illustration-for-book-greeting-card-wedding-print.jpg",
      prayer:
        "Our Father, Who art in heaven, hallowed be Thy name; Thy kingdom come; Thy will be done on earth as it is in heaven. Give us this day our daily bread; and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil. Amen.",
      backImage:
        "https://i.pinimg.com/736x/b5/16/28/b5162848519c88c49ff0715b34fe51c5.jpg",
    },
  ];

  return (
    <Section id="product">
      <Container>
        <Heading>Introducing Our Virtual Memorial Card</Heading>
        <Row className="align-items-center">
          <Col md={6}>
            <SubHeading>Honor Your Loved Ones in a Special Way</SubHeading>
            <Text>
              Create a beautiful and personalized virtual memorial card to
              celebrate the life of your loved ones. Share cherished memories,
              photos, and messages with family and friends around the world.
            </Text>
            <SubHeading>Why Choose Us?</SubHeading>
            <FeatureList>
              <li>
                <FaCheckCircle size={20} />
                Easy to create and customize
              </li>
              <li>
                <FaCheckCircle size={20} />
                Shareable with family and friends
              </li>
              <li>
                <FaCheckCircle size={20} />
                Secure and private access
              </li>
              <li>
                <FaCheckCircle size={20} />
                Upload photos and videos
              </li>
              <li>
                <FaCheckCircle size={20} />
                Beautiful design templates
              </li>
            </FeatureList>
            <StyledButton size="lg">Create Your Memorial Card Now</StyledButton>
          </Col>
          <Col md={6} className="text-center">
            <CardContainer
              onClick={handleCardClick}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <InteractiveMemorialCard data={cardsData[0]} flipped={flipped} />
            </CardContainer>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default ProductHighlight;

// src/components/MinimalLayout.js
import React from "react";
import styled from "styled-components";

const MinimalContainer = styled.div`
  width: 100%;
  height: 100vh;
  /* Add any additional styling if needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MinimalLayout = ({ children }) => {
  return <MinimalContainer>{children}</MinimalContainer>;
};

export default MinimalLayout;

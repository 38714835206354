// src/components/Archive/Layout.js
import React from "react";
import NavigationBar from "../HomePage/Navbar";
import Footer from "../HomePage/Footer";
import styled from "styled-components";

const MainContent = styled.main`
  flex: 1;
  /* Add any additional styling if needed */
`;

const Layout = ({ children }) => {
  return (
    <>
      <NavigationBar />
      <MainContent>{children}</MainContent>
      <Footer />
    </>
  );
};

export default Layout;
